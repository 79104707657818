import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Identifier } from '../models/storage.models';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const modifiedRequest = this.getRequest(req);
    return next.handle(modifiedRequest);
  }

  private getRequest(req: HttpRequest<any>): HttpRequest<any> {
    const apiKey = localStorage.getItem(Identifier.TYPE_API_KEY);
    if (!apiKey) {
      return req;
    }

    const skipInterceptor = req.headers.has('Skip-Interceptor');
    if (skipInterceptor) {
      return req.clone({ headers: req.headers.delete('Skip-Interceptor') });
    }

    return this.getRequestWithHeader(req);
  }

  private getRequestWithHeader(req: HttpRequest<any>): HttpRequest<any> {
    const token = 'Bearer ' + localStorage.getItem(Identifier.TYPE_TOKEN);
    let headers = this.getDefaultHeader(token);
    headers = this.setContentType(req.headers.get('Content-Type'), headers);

    return req.clone({ headers });
  }

  private getClinicId(): string {
    const value = localStorage.getItem(Identifier.TYPE_CLINIC_ID);
    if (!value) {
      return '';
    }
    return value.toString();
  }

  private getDefaultHeader(token: string | null): HttpHeaders {
    const apiKey = localStorage.getItem(Identifier.TYPE_API_KEY);
    const clinicGroupId = localStorage.getItem(Identifier.TYPE_CLINIC_GROUP_ID);
    const currentCulture = localStorage.getItem(Identifier.TYPE_LANGUAGE);
    const franchiseId = localStorage.getItem(Identifier.TYPE_FRANCHISE_ID);

    return new HttpHeaders({
      'X-Frame-Options': 'deny',
      ApiKey: apiKey as string,
      Authorization: token || '',
      'Access-Control-Allow-Origin': '*',
      ClinicGroupId: clinicGroupId ? clinicGroupId : '',
      ClinicId: this.getClinicId(),
      'Accept-Language': currentCulture ? currentCulture : 'en-CA',
      ClinicGroupFranchiseId: franchiseId || '',
    });
  }

  private setContentType(
    contentType: string | null,
    headers: HttpHeaders
  ): HttpHeaders {
    if (contentType === '') {
      return headers;
    }
    if (contentType) {
      return headers.append('Content-Type', contentType);
    } else {
      return headers.append('Content-Type', 'application/json');
    }
  }
}
