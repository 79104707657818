import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { filter, Subject, Subscription, takeUntil } from 'rxjs';

import DateBox from 'devextreme/ui/date_box';
import DropDownBox from 'devextreme/ui/drop_down_box';
import NumberBox from 'devextreme/ui/number_box';
import Scrollable from 'devextreme/ui/scroll_view/ui.scrollable';
import SelectBox from 'devextreme/ui/select_box';
import TextArea from 'devextreme/ui/text_area';
import TextBox from 'devextreme/ui/text_box';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { locale } from 'devextreme/localization';
import moment from 'moment';
import { LocalStorageService } from './shared/local-storage.service';
import { Identifier } from './shared/models/storage.models';
import { Store, select } from '@ngrx/store';
import { State } from './store';
import { getContentLoadingState, getUserIdle } from './store/ui/ui.selectors';
import { registerLocaleData } from '@angular/common';
import localeGerm from '@angular/common/locales/de';
import localeEnCA from '@angular/common/locales/en-CA';
import localeSpan from '@angular/common/locales/es-MX';
import localeFrCaExtra from '@angular/common/locales/extra/fr-CA';
import localeFr from '@angular/common/locales/fr';
import localeFrCA from '@angular/common/locales/fr-CA';
import { HeaderComponent } from './header/header.component';
import { Router, RouterOutlet } from '@angular/router';
import {
  EventMessage,
  EventType,
  InteractionStatus,
} from '@azure/msal-browser';
import { MsalConfig } from './auth/msal.config';
import { GraphService, SSOLoginStatus } from './auth/graph.service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { UserIdleService } from 'angular-user-idle';
import { WindowSizeModel } from './shared/layout/services/window-size.model';
import { setUserIdle, setWindowSizeChanged } from './store/ui/ui.actions';
import { unauthenticateUser } from './store/user/user.actions';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SharedPopupModule } from './shared/popup/popup.module';
import { DxButtonModule } from 'devextreme-angular';
import { ClinicApplicationSetupDto } from './shared/models/central-office/co-clinic-application-setup.model';
import { ClinicHttpService } from './shared/services/clinic-http.service';

@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    HeaderComponent,
    RouterOutlet,
    SharedPopupModule,
    TranslateModule,
    DxButtonModule,
  ],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('class') classes = '';
  title = 'Central Office Web';
  loadSub?: Subscription | null = null;
  private subscription: Subscription = new Subscription();

  userSecondsIdle?: number;
  idleTimeOutLimit = 300;
  displayIdleConfirmDialog = false;
  applicationSetup?: ClinicApplicationSetupDto;
  currClinicId = Number(localStorage.getItem(Identifier.TYPE_CLINIC_ID));
  moment = moment;

  private idleSubscription: Subscription = new Subscription();
  private jwtHelper = new JwtHelperService();

  constructor(
    private translateService: TranslateService,
    private localStorageService: LocalStorageService,
    private graphService: GraphService,
    @Optional() private msalService: MsalService,
    @Optional() private msalBroadcastService: MsalBroadcastService,

    private ref: ChangeDetectorRef,
    private store: Store<State>,
    private userIdle: UserIdleService,
    private router: Router,
    private clinicHttpService: ClinicHttpService
  ) {
    registerLocaleData(localeFr);
    registerLocaleData(localeFrCA, localeFrCaExtra);
    registerLocaleData(localeEnCA);
    registerLocaleData(localeSpan);
    registerLocaleData(localeGerm);

    translateService.addLangs(['en-CA', 'fr-CA']);

    NumberBox.defaultOptions({
      options: {
        stylingMode: 'outlined', //   'underlined'
      },
    });
    TextArea.defaultOptions({
      options: {
        stylingMode: 'outlined', //   'underlined'
      },
    });
    DateBox.defaultOptions({
      options: {
        stylingMode: 'outlined', //   'underlined'
        calendarOptions: { firstDayOfWeek: 0 },
      },
    });
    SelectBox.defaultOptions({
      options: {
        stylingMode: 'outlined', //   'underlined'
      },
    });
    DropDownBox.defaultOptions({
      options: {
        stylingMode: 'outlined', //   'underlined'
      },
    });
    TextBox.defaultOptions({
      options: {
        stylingMode: 'outlined', //  'filled', 'underlined'
      },
    });
    Scrollable.defaultOptions({
      options: {
        showScrollbar: 'onHover',
      },
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    const windowSize: WindowSizeModel = {
      width: document.documentElement.offsetWidth,
      height: document.documentElement.offsetHeight,
    };
    this.store.dispatch(setWindowSizeChanged({ windowSize: windowSize }));
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (this.displayIdleConfirmDialog) {
      if (event.key === 'Escape') {
        this.restartIdleTimer();
      }
    }
  }

  private subscribeToLanguageLocalStorage(): void {
    this.subscription.add(
      this.localStorageService
        .getStorageItem(Identifier.TYPE_LANGUAGE)
        .subscribe((lang) => this.setLanguage(lang))
    );
  }

  private setLanguage(lang: string | null): void {
    let curLang = 'en-CA';
    if (lang && lang !== '') {
      curLang = lang;
    }
    this.translateService.setDefaultLang(curLang);
    this.translateService.use(curLang);
    locale(curLang);
    switch (curLang) {
      case 'fr-CA':
        moment.locale('fr');
        break;
      default:
        moment.locale('en');
        break;
    }
    this.ref.markForCheck();
  }

  ngOnInit() {
    this.subscribeToLanguageLocalStorage();
    this.subscribeToTokenLocalStorage();

    this.subscription.add(
      this.store
        .pipe(select(getContentLoadingState))
        .subscribe((isContentLoading) => {
          Promise.resolve().then(() => {
            this.classes = isContentLoading ? 'loader-overlay loading' : '';
            this.ref.detectChanges();
          });
        })
    );

    if (MsalConfig.isEnabled) {
      this.msalService.handleRedirectObservable().subscribe();
      this.setLoginDisplay();
      this.msalService.instance.enableAccountStorageEvents();

      this.msalBroadcastService.msalSubject$
        .pipe(
          filter(
            (msg: EventMessage) =>
              msg.eventType === EventType.ACCOUNT_ADDED ||
              msg.eventType === EventType.ACCOUNT_REMOVED
          )
        )
        .subscribe((result: any) => {
          if (this.msalService.instance.getAllAccounts().length === 0) {
            this.graphService.ssoAuthenticated$.next(SSOLoginStatus.Logout);
          } else {
            this.setLoginDisplay();
            if (result?.payload?.account) {
              this.msalService.instance.setActiveAccount(
                result.payload.account
              );
              MsalConfig.account = result.payload.account;
              this.graphService.ssoAuthenticated$.next(SSOLoginStatus.Login);
            }
          }
        });

      this.msalBroadcastService.inProgress$
        .pipe(
          filter(
            (status: InteractionStatus) => status === InteractionStatus.None
          ),
          takeUntil(this._destroying$)
        )
        .subscribe(() => {
          this.setLoginDisplay();
          this.checkAndSetActiveAccount();
        });
    }
  }
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  setLoginDisplay(): void {
    this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
  }
  checkAndSetActiveAccount(): void {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.msalService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.msalService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
      MsalConfig.account = accounts[0] as any;
      this.graphService.ssoAuthenticated$.next(SSOLoginStatus.Login);
    }
  }
  ngAfterViewInit(): void {
    this.subscription.add(
      this.localStorageService
        .getStorageItem(Identifier.TYPE_IS_SSO_LOGIN)
        .subscribe((sso_login) => {
          if (sso_login === 'true') {
            setTimeout(() => {
              this.graphService.acquireToken();
              //this.loginPopup();
            }, 1000);
          }
        })
    );
  }

  getClinicApplicationSetup(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.subscription.add(
        this.clinicHttpService
          .getClinicApplicationSetup(this.currClinicId)
          .subscribe((applicationSetup) => {
            this.applicationSetup = applicationSetup;
            this.ref.markForCheck();
            resolve();
          })
      );
    });
  }

  private subscribeToTokenLocalStorage(): void {
    this.subscription.add(
      this.localStorageService
        .getStorageItem(Identifier.TYPE_LOGIN_STAFF)
        .subscribe(async (staffInfo) => {
          await this.getClinicApplicationSetup();

          if (staffInfo) {
            const entity = JSON.parse(staffInfo);
            this.setIdleCheck(
              this.applicationSetup?.staffSessionInactivityTimeoutInMinutes ||
                1440,
              entity.token
            );
          } else {
            this.flushIdleTimer();
          }
        })
    );
  }

  private setIdleCheck(inactivityInMinutes: number, token: string): void {
    this.flushIdleTimer();
    this.userIdle.setConfigValues({
      idle: inactivityInMinutes * 60,
      timeout: this.idleTimeOutLimit,
      ping: 120,
    });
    if (token && !this.jwtHelper.isTokenExpired(token)) {
      this.startWatching();
      this.idleSubscription.add(
        this.store
          .pipe(
            select(getUserIdle),
            filter((idle: boolean | undefined) => !!idle)
          )
          .subscribe(() => this.showConfirmDialog())
      );
      this.subscribeToTimerStart();
      this.subscribeToTimerTimeOut();
    } else {
      this.store.dispatch(setUserIdle({ isIdle: false }));
    }
  }

  private subscribeToTimerStart(): void {
    this.idleSubscription.add(
      this.userIdle.onTimerStart().subscribe((count) => {
        if (count === 1) {
          this.store.dispatch(setUserIdle({ isIdle: true }));
        }
        this.userSecondsIdle = count;
        console.log(this.userSecondsIdle);
        this.ref.markForCheck();
      })
    );
  }

  private subscribeToTimerTimeOut(): void {
    this.idleSubscription.add(
      this.userIdle.onTimeout().subscribe(() => {
        if (this.displayIdleConfirmDialog) {
          this.displayIdleConfirmDialog = false;
        }
        this.endIdleTimerAndSession();
      })
    );
  }

  private showConfirmDialog(): void {
    this.displayIdleConfirmDialog = true;
    this.ref.markForCheck();
  }

  private startWatching(): void {
    this.userIdle.startWatching();
  }

  private flushIdleTimer(): void {
    this.userIdle.stopWatching();
    this.displayIdleConfirmDialog = false;
    this.idleSubscription.unsubscribe();
    this.idleSubscription = new Subscription();
  }

  ngOnDestroy() {
    if (this.loadSub) this.loadSub.unsubscribe();
    this.loadSub = null;
  }

  endIdleTimerAndSession(): void {
    this.flushIdleTimer();
    this.store.dispatch(setUserIdle({ isIdle: false }));
    // this.dynamicLoaderService.removeAllDynamicComponents();
    this.logout();
  }

  restartIdleTimer(): void {
    this.userIdle.stopWatching();
    this.displayIdleConfirmDialog = false;
    this.userIdle.startWatching();
    this.store.dispatch(setUserIdle({ isIdle: false }));
  }

  logout() {
    // this.clearTimer();
    this.store.dispatch(unauthenticateUser());
    this.router.navigate(['/auth']);
  }
}
