<div class="app-layout">
  <app-header></app-header>
  <div class="app-content">
    <router-outlet></router-outlet>
  </div>
</div>

<shared-popup [visible]="displayIdleConfirmDialog" [panelClass]="'popup w600'" [animation]="'scale'"
  [outsideClick]="false">
  <div *appPopupTemplate>
    <div class="app-modal app-modal-simple">
      <div class="app-modal-header">
        <h1 class="fs28 acenter p0 pl40">
          {{ 'message.user_idle_message_title' | translate }}
        </h1>
      </div>
      <div class="app-modal-content acenter text-dark">
        <div class="mb20 fs17">
          <i class="icon-exclamation_line text-warning fs24 vam"></i>
          {{ 'message.user_idle_message_content' | translate }}
        </div>
        <div class="p15 bg-xxlight br4">
          <div>
            {{ 'message.user_idle_message_time_left' | translate }}
          </div>
          <span class="fs30 fw500">{{
            moment
            .utc(
            moment
            .duration(idleTimeOutLimit, 'seconds')
            .subtract(userSecondsIdle, 'seconds')
            .asMilliseconds()
            )
            .format('mm:ss')
            }}</span>
        </div>
      </div>
      <div class="app-modal-footer">
        <div class="buttons-wrapper">
          <dx-button type="default" (click)="endIdleTimerAndSession()" text="{{ 'actions.sign_out' | translate }}">
          </dx-button>
          <dx-button type="default" (click)="restartIdleTimer()" text="{{ 'actions.continue' | translate }}">
          </dx-button>
        </div>
      </div>
    </div>
  </div>
</shared-popup>