import { Action, createReducer, on } from '@ngrx/store';

import {
  CountryDropDownListItemDto,
  DropDownListItemDto,
} from '../../shared/models/menus/drop-down-list-item.model';
import * as actions from './initial-data.actions';
import { AcctGlaccountDto } from '../../shared/models/accounting/acctglaccount.model';
import { GroupForAnalyticDto } from '../../shared/models/menus/group-for-analytic.model';
import { SysCorrLanguageDto } from '../../shared/models/system/sys-corr-language';
import { SysMembershipPlanTypeDto } from '../../shared/models/system/sys-membership-plan-type';
import { SysBillingFrequencyDto } from '../../shared/models/system/sys-billing-frequency';
import { CoAcctDiscountCouponDto } from '../../shared/models/accounting/acct-discount-coupon';
import { AcctInvoiceStyleDto } from '../../shared/models/accounting/acct-invoice-style';
import { SysDisciplineDto } from '../../shared/models/system/sys-discipline.model';

export interface InitialDataState {
  clinicGroupDropdown?: DropDownListItemDto[] | null;
  measurementUnitsDropdown?: DropDownListItemDto[] | null;
  acctGlaccounts?: AcctGlaccountDto[] | null;
  groupForAnalytics?: GroupForAnalyticDto[] | null;
  disciplinesDropDown?: DropDownListItemDto[] | null;
  sysDisciplines?: SysDisciplineDto[] | null;
  citiesDropDown?: DropDownListItemDto[] | null;
  countriesDropDown?: CountryDropDownListItemDto[] | null;
  salutationDropDown?: DropDownListItemDto[] | null;
  franchiseCorrLanguages?: SysCorrLanguageDto[] | null;
  sysCorrLanguages?: SysCorrLanguageDto[] | null;
  membershipPlanTypes?: SysMembershipPlanTypeDto[] | null;
  membershipRenewalOptions?: DropDownListItemDto[] | null;
  billingFrequencies?: SysBillingFrequencyDto[] | null;
  membershipCoAcctDiscountCoupons?: CoAcctDiscountCouponDto[] | null;
  clinicGroupFranchiseAcctInvoiceStyles?: AcctInvoiceStyleDto[] | null;
}

const initialDataState: InitialDataState = {
  clinicGroupDropdown: null,
  measurementUnitsDropdown: null,
  acctGlaccounts: null,
  groupForAnalytics: null,
  disciplinesDropDown: null,
  sysDisciplines: null,
  citiesDropDown: null,
  countriesDropDown: null,
  salutationDropDown: null,
  franchiseCorrLanguages: null,
  sysCorrLanguages: null,
  membershipPlanTypes: null,
  membershipRenewalOptions: null,
  billingFrequencies: null,
  membershipCoAcctDiscountCoupons: null,
  clinicGroupFranchiseAcctInvoiceStyles: null,
};

const drReducer = createReducer(
  initialDataState,
  on(actions.setClinicGroupsDropDownList, (state, { clinicGroupDropdown }) => ({
    ...state,
    clinicGroupDropdown,
  })),
  on(
    actions.setMeasurementUnitsDropDownListItem,
    (state, { measurementUnitsDropdown }) => ({
      ...state,
      measurementUnitsDropdown,
    })
  ),
  on(actions.setAcctGlaccounts, (state, { acctGlaccounts }) => ({
    ...state,
    acctGlaccounts,
  })),
  on(actions.setGroupForAnalytics, (state, { groupForAnalytics }) => ({
    ...state,
    groupForAnalytics,
  })),
  on(
    actions.setSysDisciplinesDropDownListItem,
    (state, { disciplinesDropDown }) => ({
      ...state,
      disciplinesDropDown,
    })
  ), on(
    actions.setSysDisciplines,
    (state, { sysDisciplines }) => ({
      ...state,
      sysDisciplines,
    })
  ),
  on(actions.setCitiesDropDown, (state, { citiesDropDown }) => ({
    ...state,
    citiesDropDown,
  })),
  on(actions.setCountriesDropDown, (state, { countriesDropDown }) => ({
    ...state,
    countriesDropDown,
  })),
  on(actions.setSalutationDropDown, (state, { salutationDropDown }) => ({
    ...state,
    salutationDropDown,
  })),
  on(
    actions.setFranchiseCorrLanguages,
    (state, { franchiseCorrLanguages }) => ({
      ...state,
      franchiseCorrLanguages,
    })
  ),
  on(actions.setSysCorrLanguages, (state, { sysCorrLanguages }) => ({
    ...state,
    sysCorrLanguages,
  })),
  on(actions.setMembershipPlanTypes, (state, { membershipPlanTypes }) => ({
    ...state,
    membershipPlanTypes,
  })),
  on(
    actions.setMembershipRenewalOptions,
    (state, { membershipRenewalOptions }) => ({
      ...state,
      membershipRenewalOptions,
    })
  ),
  on(actions.setBillingFrequencies, (state, { billingFrequencies }) => ({
    ...state,
    billingFrequencies,
  })),
  on(
    actions.setMembershipCoAcctDiscountCoupons,
    (state, { membershipCoAcctDiscountCoupons }) => ({
      ...state,
      membershipCoAcctDiscountCoupons,
    })
  ),
  on(
    actions.setClinicGroupFranchiseAcctInvoiceStyles,
    (state, { clinicGroupFranchiseAcctInvoiceStyles }) => ({
      ...state,
      clinicGroupFranchiseAcctInvoiceStyles,
    })
  )
);

export function initialDataReducer(
  state: InitialDataState | undefined,
  action: Action
): InitialDataState {
  return drReducer(state, action);
}
