import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { interval, Observable, Subscription, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectAuthenticatedUser } from '../store/user/user.selectors';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../shared/local-storage.service';
import { LanguagesEnum } from '../shared/models/corr-language.enum';
import { getLanguageStatus } from '../shared/language/utils/language.utils';
import { Identifier } from '../shared/models/storage.models';
import { AsyncPipe } from '@angular/common';
import { InitialStartupService } from '../auth/initial-startup.service';
import { GraphService, SSOLoginStatus } from '../auth/graph.service';
import { MsalService } from '@azure/msal-angular';
import { DxButtonModule } from 'devextreme-angular';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { SharedPopupModule } from '../shared/popup/popup.module';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  standalone: true,
  imports: [TranslateModule, AsyncPipe, DxButtonModule, SharedPopupModule],
})
export class HeaderComponent implements OnInit, OnDestroy {
  isAuthenticated$: Observable<boolean> | undefined | null = null;
  languages = [
    { id: LanguagesEnum.EN_CA, name: 'English' },
    { id: LanguagesEnum.FR_CA, name: 'French' },
  ];
  currentLanguage?: string;
  languagesEnum = LanguagesEnum;
  newUpdateAvailable = false;
  showRefreshPopup = false;
  languageStatus = getLanguageStatus();
  subscription = new Subscription();
  constructor(
    private authService: InitialStartupService,
    private store: Store<any>,
    public translateService: TranslateService,
    private localStorageService: LocalStorageService,
    private graphService: GraphService,
    private msalService: MsalService,
    private router: Router,
    private swUpdate: SwUpdate,
    private ref: ChangeDetectorRef
  ) {
    if (this.swUpdate.isEnabled) {


      this.subscription.add(this.swUpdate.versionUpdates.subscribe((event) => {
        if (event.type === 'VERSION_READY') {
          this.promptUser();
        }
      }));

      this.subscription.add(
        interval(1800000)
          .pipe(
            switchMap(() => {
              return this.swUpdate.checkForUpdate();
            })
          )
          .subscribe((isUpdateAvailable) => {
            if (isUpdateAvailable) {

              // Notify user or automatically refresh
              this.promptUser();
            }
          })
      );
    }
  }
  promptUser() {
    this.newUpdateAvailable = true;
    this.showRefreshPopup = true;
    this.ref.markForCheck();
  }
  updateToLatest(): void {
    this.swUpdate.activateUpdate().then(() => document.location.reload());
  }
  ngOnInit() {
    this.isAuthenticated$ = this.store.select(selectAuthenticatedUser);

    this.currentLanguage =
      this.localStorageService.getStorageItem(Identifier.TYPE_LANGUAGE).value ??
      this.translateService.getDefaultLang();
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
  onLogout() {
    const sso_login = localStorage.getItem(Identifier.TYPE_IS_SSO_LOGIN);
    if (sso_login === 'true') {
      this.logoutADUser();
    } else {
      this.authService.logout();
    }
  }

  logoutADUser(): void {
    this.graphService.ssoAuthenticated$.next(SSOLoginStatus.Logout);
    const endSessionRequest = {
      mainWindowRedirectUri: '/',
    };
    this.msalService.logoutPopup(endSessionRequest);
    setTimeout(() => {
      this.authService.logout();
    });
  }

  onLanguageChange(lng: string) {
    this.localStorageService.setStorageItem(Identifier.TYPE_LANGUAGE, lng);
    this.currentLanguage = lng;
  }
  onGoTo(path: string) {
    this.router.navigate([path]);
  }

  onBackClicked() {
    this.router.navigate(['/home']);
  }
}
