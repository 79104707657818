import { Routes } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';

export const marketingRoutes: Routes = [
  {
    path: 'marketing',
    loadComponent: () =>
      import('./marketing.component').then((m) => m.MarketingComponent),
    canActivate: [AuthGuard],
  },
];
