import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpHeaders,
} from '@angular/common/http';
import { take, exhaustMap } from 'rxjs/operators';

import { LocalStorageService } from '../shared/local-storage.service';
import { environment } from '../../environments/environment';
import { Store } from '@ngrx/store';
import { selectUser } from '../store/user/user.selectors';
import { HelpersService } from '../shared/utils/helpers/helpers.service';
import { TranslateService } from '@ngx-translate/core';
import { Identifier } from '../shared/models/storage.models';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(
    private store: Store<any>,
    private helper: HelpersService,
    private localStorageService: LocalStorageService,
    private translateService: TranslateService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const skipInterceptor = req.headers.has('Skip-Interceptor');
    if (skipInterceptor) {
      const headers = req.headers.delete('Skip-Interceptor');
      return next.handle(req.clone({ headers }));
    }

    return this.store.select(selectUser).pipe(
      take(1),
      exhaustMap((user) => {
        const franchiseId = localStorage.getItem(Identifier.TYPE_FRANCHISE_ID);
        const currentCulture =
          this.localStorageService.getStorageItem(Identifier.TYPE_LANGUAGE)
            .value ||
          this.translateService.getDefaultLang() ||
          'en-CA';

        const apiKey = environment.api_key;

        let headers = req.headers
          .append('X-Frame-Options', 'deny')
          .append('ApiKey', apiKey || '')
          .append('Access-Control-Allow-Origin', '*')
          .append('Accept-Language', currentCulture)
          .append('ClinicGroupFranchiseId', franchiseId || '');

        if (headers.has('Content-Type') && headers.get('Content-Type') === '') {
          headers = headers.delete('Content-Type');
        } else {
          headers = headers.append('Content-Type', 'application/json');
        }

        if (!headers.has('Authorization')) {
          headers = headers.append(
            'Authorization',
            this.helper.userIsAuthenticated(user) ? 'Bearer ' + user.token : ''
          );
        }

        const modifiedReq = req.clone({
          //params: new HttpParams().set('auth', user.validToken),
          headers: headers,
        });
        return next.handle(modifiedReq);
      })
    );
  }
}
