import { Routes } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { CanDeactivateGuard } from '../shared/deactivate-guard/deactivate.guard';

export const configurationRoutes: Routes = [
  // {
  //   path: 'configuration',
  //   loadComponent: () =>
  //     import('./configuration.component').then((m) => m.ConfigurationComponent),
  //   canActivate: [AuthGuard],
  // }
  {
    path: 'postings',
    loadComponent: () =>
      import('./accounting-and-billing/posting/postings.component').then(
        (m) => m.PostingsComponent
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'disciplines',
    loadComponent: () =>
      import('./services-products/disciplines/disciplines.component').then(
        (m) => m.DisciplinesComponent
      ),
    canActivate: [AuthGuard],
    data: { menuId: 7053 },
  },
  {
    path: 'services',
    loadComponent: () =>
      import('./services-products/services/services.component').then(
        (m) => m.ServicesComponent
      ),
    canActivate: [AuthGuard],
    data: { menuId: 7058 },
  },
  {
    path: 'practitioners',
    loadComponent: () =>
      import('./teams/practitioners/practitioners.component').then(
        (m) => m.PractitionerComponent
      ),
    canActivate: [AuthGuard],
    data: { menuId: 7065 },
  },
  {
    path: 'process-queue-category',
    loadComponent: () =>
      import(
        './process-queues/process-queue-category/process-queue-category.component'
      ).then((m) => m.ProcessQueueCategoryComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7209 },
  },
  {
    path: 'process-queues',
    loadComponent: () =>
      import('./process-queues/process-queues.component').then(
        (m) => m.ProcessQueuesComponent
      ),
    canActivate: [AuthGuard],
    data: { menuId: 7210 },
  },
  {
    path: 'funders',
    loadComponent: () =>
      import('./teams/funders/funders.component').then(
        (m) => m.FoundersComponent
      ),
    canActivate: [AuthGuard],
    data: { menuId: 7067 },
  },
  {
    path: 'adjusters',
    loadComponent: () =>
      import('./teams/adjusters/adjusters.component').then(
        (m) => m.AdjustersComponent
      ),
    canActivate: [AuthGuard],
    data: { menuId: 7180 },
  },
  {
    path: 'referrers',
    loadComponent: () =>
      import('./teams/referrers/referrers.component').then(
        (m) => m.ReferrersComponent
      ),
    canActivate: [AuthGuard],
    data: { menuId: 7181 },
  },
  {
    path: 'service-categories',
    loadComponent: () =>
      import(
        './services-products/service-categories/service-categories.component'
      ).then((m) => m.ServiceCategoriesComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7059 },
  },
  {
    path: 'products',
    loadComponent: () =>
      import('./services-products/products/products.component').then(
        (m) => m.ProductsComponent
      ),
    canActivate: [AuthGuard],
    data: { menuId: 7056 },
  },
  {
    path: 'protocols',
    loadComponent: () =>
      import(
        './cases/case-protocol-templates/protocol-templates.component'
      ).then((m) => m.ProtocolTemplatesComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7005 },
  },
  {
    path: 'chart-note-types',
    loadComponent: () =>
      import('./chart-notes/chart-note-types/chart-note-types.component').then(
        (m) => m.ChartNoteTypesComponent
      ),
    canActivate: [AuthGuard],
    data: { menuId: 7042 },
  },
  {
    path: 'chart-note-headers',
    loadComponent: () =>
      import(
        './chart-notes/chart-note-headers/chart-note-headers.component'
      ).then((m) => m.ChartNoteHeadersComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7043 },
  },
  {
    path: 'case-templates',
    loadComponent: () =>
      import('./cases/case-templates/case-templates.component').then(
        (m) => m.CaseTemplatesComponent
      ),
    canActivate: [AuthGuard],
    data: { menuId: 7003 },
  },
  {
    path: 'case-phase-and-categories',
    loadComponent: () =>
      import(
        './cases/case-phase-and-categories/case-phase-and-categories.component'
      ).then((m) => m.CasePhaseAndCategoriesComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7004 },
  },
  {
    path: 'case-discharge-reasons',
    loadComponent: () =>
      import(
        './cases/case-discharge-reasons/case-discharge-reasons.component'
      ).then((m) => m.CaseDischargeReasonsComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7006 },
  },
  {
    path: 'case-coverage-types',
    loadComponent: () =>
      import(
        './cases/case-coverage-plan-types/case-coverage-plan-types.component'
      ).then((m) => m.CaseCoveragePlanTypesComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7008 },
  },
  {
    path: 'case-discharge-statuses',
    loadComponent: () =>
      import(
        './cases/case-discharge-statuses/case-discharge-statuses.component'
      ).then((m) => m.CaseDischargeStatusesComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7170 },
  },
  {
    path: 'case-contracts',
    loadComponent: () =>
      import('./cases/case-contracts/case-contracts.component').then(
        (m) => m.CaseContractsReasonsComponent
      ),
    canActivate: [AuthGuard],
    data: { menuId: 7007 },
  },
  {
    path: 'product-categories',
    loadComponent: () =>
      import(
        './services-products/product-categories/product-categories.component'
      ).then((m) => m.ProductCategoriesComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7057 },
  },
  {
    path: 'gift-certificates',
    loadComponent: () =>
      import(
        './services-products/gift-certificates/gift-certificates.component'
      ).then((m) => m.GiftCertificatesComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7055 },
  },
  {
    path: 'discount-coupons',
    loadComponent: () =>
      import(
        './services-products/discount-coupons/discount-coupons.component'
      ).then((m) => m.DiscountCouponsComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7054 },
  },
  {
    path: 'discipline-pricing-levels',
    loadComponent: () =>
      import(
        './services-products/discipline-pricing-levels/discipline-pricing-levels.component'
      ).then((m) => m.DisciplinePricingLevelsComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7185 },
  },
  {
    path: 'user-logins',
    loadComponent: () =>
      import('./security/user-logins/user-logins.component').then(
        (m) => m.UserLoginsComponent
      ),
    canActivate: [AuthGuard],
    data: { menuId: 7051 },
  },
  {
    path: 'security-roles',
    loadComponent: () =>
      import('./security/security-roles/security-roles.component').then(
        (m) => m.SecurityRolesComponent
      ),
    canActivate: [AuthGuard],
    data: { menuId: 7052 },
  },
  {
    path: 'admin-staff',
    loadComponent: () =>
      import('./administration/admin-staff/admin-staff.component').then(
        (m) => m.AdminStaffComponent
      ),
    canActivate: [AuthGuard],
    data: { menuId: 7066 },
  },
  {
    path: 'tax-rates',
    loadComponent: () =>
      import('./account/tax-rates/tax-rates.component').then(
        (m) => m.TaxRatesComponent
      ),
    canActivate: [AuthGuard],
    data: { menuId: 7188 },
  },
  {
    path: 'compensation-taxes',
    loadComponent: () =>
      import(
        './compensation/compensation-taxes/compensation-taxes.component'
      ).then((m) => m.CompensationTaxesComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7016 },
  },
  {
    path: 'practitioner-compensations',
    loadComponent: () =>
      import(
        './compensation/practitioner-compensations/practitioner-compensations.component'
      ).then((m) => m.PractitionerCompensationsComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'compensation-packages',
    loadComponent: () =>
      import(
        './compensation/compensation-package/compensation-package.component'
      ).then((m) => m.CompensationPackagesComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7018 },
  },
  {
    path: 'practitioner-compensations-statutory',
    loadComponent: () =>
      import(
        './compensation/statutory-holidays-compensations/statutory-holidays-compensations.component'
      ).then((m) => m.StatutoryHolidaysCompensationsComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'membership-plans',
    loadComponent: () =>
      import('./membership/membership-plans/membership-plans.component').then(
        (m) => m.MembershipPlansComponent
      ),
    canActivate: [AuthGuard],
    data: { menuId: 7048 },
  },
  {
    path: 'membership-reasons',
    loadComponent: () =>
      import(
        './membership/membership-reasons/membership-reasons.component'
      ).then((m) => m.MembershipReasonsComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7049 },
  },
  {
    path: 'prepayments',
    loadComponent: () =>
      import('./services-products/prepayments/prepayments.component').then(
        (m) => m.PrepaymentsComponent
      ),
    canActivate: [AuthGuard],
    data: { menuId: 7186 },
  },
  {
    path: 'membership-products',
    loadComponent: () =>
      import(
        './membership/membership-products/membership-products.component'
      ).then((m) => m.MembershipProductsComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7048 },
  },
  {
    path: 'sms-templates',
    loadComponent: () =>
      import('./communication/sms-templates/sms-templates.component').then(
        (m) => m.SmsTemplatesComponent
      ),
    canActivate: [AuthGuard],
    data: { menuId: 7014 },
  },
  {
    path: 'label-templates',
    loadComponent: () =>
      import('./communication/label-templates/label-templates.component').then(
        (m) => m.LabelTemplatesComponent
      ),
    canActivate: [AuthGuard],
    data: { menuId: 7015 },
  },
  {
    path: 'note-templates',
    loadComponent: () =>
      import('./communication/note-templates/note-templates.component').then(
        (m) => m.NoteTemplatesComponent
      ),
    canActivate: [AuthGuard],
    data: { menuId: 7013 },
  },
  {
    path: 'note-alerts-task-categories',
    loadComponent: () =>
      import(
        './communication/notes-alerts-task-categories/notes-alerts-task-categories.component'
      ).then((m) => m.NotesAlertsTaskCategoriesComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7200 },
  },
  {
    path: 'secure-messages',
    loadComponent: () =>
      import('./communication/secure-messages/secure-messages.component').then(
        (m) => m.SecureMessagesComponent
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'email-templates',
    loadComponent: () =>
      import('./communication/email-templates/email-templates.component').then(
        (m) => m.EmailTemplatesComponent
      ),
    canActivate: [AuthGuard],
    data: { menuId: 7207 },
  },
  {
    path: 'secured-message-templates',
    loadComponent: () =>
      import(
        './communication/secure-message-template/secured-message-template.component'
      ).then((m) => m.SecureMessageTemplateComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7213 },
  },
  {
    path: 'membership-discount-coupons',
    loadComponent: () =>
      import(
        './membership/membership-discount-coupons/membership-discount-coupons.component'
      ).then((m) => m.MembershipDiscountCouponsComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7196 },
  },
  {
    path: 'compensation-fee-products-setup',
    loadComponent: () =>
      import(
        './franchisees_fee/compensation-fee-products-setup/compensation-fee-products-setup.component'
      ).then((m) => m.CompensationFeeProductsSetupComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7197 },
  },
  {
    path: 'franchisee-fee-management',
    loadComponent: () =>
      import('./franchisees_fee/management/management.component').then(
        (m) => m.ManagementComponentComponent
      ),
    canActivate: [AuthGuard],
    data: { menuId: 7199 },
  },
  {
    path: 'education-resources',
    loadComponent: () =>
      import(
        './chart-notes/education-resources/educational-resource-container/educational-resource-container.component'
      ).then((m) => m.EducationalResourceContainerComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7047 },
  },
  {
    path: 'chart-note-categories',
    loadComponent: () =>
      import(
        './chart-notes/chart-note-categories/chart-note-categories.component'
      ).then((m) => m.ChartNoteCategoriesComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7044 },
  },
  {
    path: 'chart-note-template-smart-docs',
    loadComponent: () =>
      import(
        './chart-notes/pdf-form-templates/pdf-form-templates.component'
      ).then((m) => m.PdfFromTemplatesCompoennt),
    canActivate: [AuthGuard],
  },
  {
    path: 'chart-note-web-form-templates',
    loadComponent: () =>
      import(
        './chart-notes/web-form-templates/web-form-templates.component'
      ).then((m) => m.WebbFormTemplatesComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7046 },
  },
  {
    path: 'summary-information',
    loadComponent: () =>
      import(
        './settings/summary-information/summary-information.component'
      ).then((m) => m.SummaryInformationComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7064 },
  },
  {
    path: 'entity-cities',
    loadComponent: () =>
      import(
        './custom-fields-and-pick-lists/entity-cities/entity-cities.component'
      ).then((m) => m.CitiesComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7034 },
  },
  {
    path: 'marketing-segments',
    loadComponent: () =>
      import(
        './custom-fields-and-pick-lists/marketing-segments/marketing-segments.component'
      ).then((m) => m.MarketingSegmentsComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'reservation-categories',
    loadComponent: () =>
      import(
        './custom-fields-and-pick-lists/reservation-categories/reservation-categories.component'
      ).then((m) => m.ReservationCategoriesComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'product-styles',
    loadComponent: () =>
      import(
        './custom-fields-and-pick-lists/product-styles/product-styles.component'
      ).then((m) => m.ProductStylesComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'invoice-prefixes',
    loadComponent: () =>
      import(
        './custom-fields-and-pick-lists/invoice-prefix/invoice-prefix.component'
      ).then((m) => m.InvoicePrefixComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'custom-client-fields',
    loadComponent: () =>
      import(
        './custom-fields-and-pick-lists/custom-client-fields/custom-client-fields.component'
      ).then((m) => m.ClientCustomFieldsComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7020 },
  },
  {
    path: 'custom-case-fields',
    loadComponent: () =>
      import(
        './custom-fields-and-pick-lists/custom-case-fields/custom-case-fields.component'
      ).then((m) => m.CaseCustomFieldsComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7021 },
  },
  {
    path: 'custom-appointment-fields',
    loadComponent: () =>
      import(
        './custom-fields-and-pick-lists/custom-appointment-fields/custom-appointment-fields.component'
      ).then((m) => m.AppointmentCustomFieldsComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7022 },
  },
  {
    path: 'out-reason',
    loadComponent: () =>
      import(
        './custom-fields-and-pick-lists/out-reasons/out-reasons.component'
      ).then((m) => m.OutReasonsComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7023 },
  },
  {
    path: 'equipments',
    loadComponent: () =>
      import(
        './custom-fields-and-pick-lists/equipment/equipment.component'
      ).then((m) => m.EquipmentComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7024 },
  },
  {
    path: 'rooms-and-room-reservation-reason',
    loadComponent: () =>
      import(
        './custom-fields-and-pick-lists/rooms/rooms-container/rooms-container.component'
      ).then((m) => m.RoomsContainerComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7025 },
  },
  {
    path: 'required-fields',
    loadComponent: () =>
      import(
        './custom-fields-and-pick-lists/required-fields/required-fields.component'
      ).then((m) => m.RequiredFieldsComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7191 },
  },
  {
    path: 'revenue-divisions',
    loadComponent: () =>
      import(
        './custom-fields-and-pick-lists/revenue-divisions/revenue-divisions.component'
      ).then((m) => m.RevenueDivisionsComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'clinics-setup',
    loadComponent: () =>
      import('./franchisees_fee/clinics-setup/clinics-setup.component').then(
        (m) => m.ClinicsSetupComponent
      ),
    canActivate: [AuthGuard],
    data: { menuId: 7198 },
  },
  {
    path: 'cancel-no-show-reasons',
    loadComponent: () =>
      import(
        './custom-fields-and-pick-lists/cancelation-no-show-reasons/cancelation-no-show-reasons.component'
      ).then((m) => m.CancelationNoShowReasonsComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7026 },
  },
  {
    path: 'regions-subregions',
    loadComponent: () =>
      import(
        './custom-fields-and-pick-lists/regions-subregions/regions-subregions.component'
      ).then((m) => m.RegionsSubregionsComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7027 },
  },
  {
    path: 'disabilities',
    loadComponent: () =>
      import(
        './custom-fields-and-pick-lists/disabilities/disabilities.component'
      ).then((m) => m.DisabilitiesComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7029 },
  },
  {
    path: 'ethnicities',
    loadComponent: () =>
      import(
        './custom-fields-and-pick-lists/ethnicities/ethnicities.component'
      ).then((m) => m.EthnicitiesComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7029 },
  },
  {
    path: 'interests-and-groups',
    loadComponent: () =>
      import(
        './custom-fields-and-pick-lists/interests-and-groups/interests-and-groups.component'
      ).then((m) => m.InterestsAndGroupsComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7030 },
  },
  {
    path: 'mother-tongues',
    loadComponent: () =>
      import(
        './custom-fields-and-pick-lists/mother-tongues/mother-tongues.component'
      ).then((m) => m.MotherTonguesComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7031 },
  },
  {
    path: 'occupations',
    loadComponent: () =>
      import(
        './custom-fields-and-pick-lists/occupations/occupations.component'
      ).then((m) => m.OccupationsComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7032 },
  },
  {
    path: 'vip-types',
    loadComponent: () =>
      import(
        './custom-fields-and-pick-lists/vip-types/vip-types.component'
      ).then((m) => m.VipTypesComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7033 },
  },
  {
    path: 'injuries-and-families',
    loadComponent: () =>
      import(
        './custom-fields-and-pick-lists/injuries-and-families/injuries-and-families.component'
      ).then((m) => m.InjuriesAndFamiliesComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7035 },
  },
  {
    path: 'case-body-parts',
    loadComponent: () =>
      import(
        './custom-fields-and-pick-lists/case-body-parts/case-body-parts.component'
      ).then((m) => m.CaseBodyPartsComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7190 },
  },
  {
    path: 'case-group-types',
    loadComponent: () =>
      import(
        './custom-fields-and-pick-lists/case-group-types/case-group-types.component'
      ).then((m) => m.CaseGroupTypesComponent),
    canActivate: [AuthGuard],
    data: { menuId: 7202 },
  },
  {
    path: 'funder-types',
    loadComponent: () =>
      import(
        './custom-fields-and-pick-lists/funder-types/funder-types.component'
      ).then((m) => m.FunderTypesComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'funder-parent-companies',
    loadComponent: () =>
      import(
        './custom-fields-and-pick-lists/funder-parent-companies/funder-parent-companies.component'
      ).then((m) => m.FunderParentCompaniesComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'referrer-referring-channels',
    loadComponent: () =>
      import(
        './custom-fields-and-pick-lists/referrer-referring-channels/referrer-referring-channels.component'
      ).then((m) => m.ReferrerReferringChannelsComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'referrer-referring-groups-categories',
    loadComponent: () =>
      import(
        './custom-fields-and-pick-lists/referrer-referring-groups-categories/referrer-referring-groups-categories.component'
      ).then((m) => m.ReferrerReferringGroupsCategoriesComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'inventory-adjustment-reasons',
    loadComponent: () =>
      import(
        './custom-fields-and-pick-lists/adjustment-reasons/adjustment-reasons.component'
      ).then((m) => m.InventoryAdjustmentReasonComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'group-for-analytics',
    loadComponent: () =>
      import(
        './custom-fields-and-pick-lists/group-for-analytics/group-for-analytics.component'
      ).then((m) => m.GroupForAnalyticsComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'external-institutions',
    loadComponent: () =>
      import(
        './custom-fields-and-pick-lists/external-institutions/external-institutions.component'
      ).then((m) => m.ExternalInstitutionsComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'calendar-tabs',
    loadComponent: () =>
      import('./calendar/calendar-tabs/calendar-tabs.component').then(
        (m) => m.CalendarTabsComponent
      ),
    canActivate: [AuthGuard],
    data: { menuId: 7002 },
  },
  {
    path: 'calendar-colors',
    loadComponent: () =>
      import('./calendar/calendar-colors/calendar-colors.component').then(
        (m) => m.CalendarColorsComponent
      ),
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'clinic-application-configuration',
    loadComponent: () =>
      import(
        './clinic/application-configuration/application-configuration.component'
      ).then((m) => m.ApplicationConfigurationComponent),
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'clinic-office-hours-closure',
    loadComponent: () =>
      import(
        './clinic/clinic-open-hours-closure/clinic-open-hours-closure.component'
      ).then((m) => m.ClinicOpenHoursClosureComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'franchise',
    loadComponent: () =>
      import(
        './settings/clinic-group-franchise/clinic-group-franchise.component'
      ).then((m) => m.ClinicGroupFranchiseComponent),
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'communication-reminders',
    loadComponent: () =>
      import(
        './communication/communication-types-popup/communication-types-popup.component'
      ).then((m) => m.CommunicationTypesPopupComponent),
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'clinic-information',
    loadComponent: () =>
      import('./clinic/clinic-information/clinic-information.component').then(
        (m) => m.ClinicInformationComponent
      ),
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'chart-note-sharing-teams',
    loadComponent: () =>
      import(
        './chart-notes/chart-note-sharing-teams/chart-note-sharing-teams.component'
      ).then((m) => m.ChartNoteSharingTeamsComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'accounting-configuration',
    loadComponent: () =>
      import(
        './accounting-and-billing/accounting-configuration/accounting-configuration.component'
      ).then((m) => m.AccountingConfigurationComponent),
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'administration-teams',
    loadComponent: () =>
      import(
        './administration/administration-teams/administration-teams.component'
      ).then((m) => m.AdministrationTeamsComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'client-portal-franchise-configuration',
    loadComponent: () =>
      import(
        './client-portal/franchise-configuration/franchise-configuration.component'
      ).then((m) => m.FranchiseConfigurationComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'franchise-account-setup',
    loadComponent: () =>
      import(
        './settings/franchise-account-setup/franchise-account-setup.component'
      ).then((m) => m.FranchiseAccountSetupComponent),
    canActivate: [AuthGuard],
  },
];
