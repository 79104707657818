<div class="header-wrapper light">
  <div class="left pr10">
    <div class="left header-line"></div>
    <a href="javascript:void(0)" (click)="onBackClicked()">
      <img src="assets/images/logos/cmco-logo-text-peacock.svg" alt="" />
    </a>
    @if(isAuthenticated$ | async) {
    <div class="lng-cont">
      <dx-button class="button-header ml20" icon="icon-dashboard" (click)="onBackClicked()"
        hint="{{'header.home' | translate}}">
        <i class="icon-dashboard"></i>
      </dx-button>
    </div>
    }
  </div>
  <div class="middle"></div>
  <div class="right">
    @if(isAuthenticated$ | async) {
    <div class="lng-cont">

      @if(newUpdateAvailable) {
      <dx-button class="button-header warning mr30" icon="refresh" (click)="updateToLatest()"
        hint="{{'actions.refresh' | translate}}">
        <i class="icon-arrows-cw"></i>
      </dx-button>
      }

      <dx-button class="button-header lang" [class.disabled]="currentLanguage === languagesEnum.EN_CA"
        text="{{ 'language.en' | translate }}" (click)="onLanguageChange(languagesEnum.EN_CA)">
      </dx-button>
      @if(languageStatus.fr) {
      <dx-button class="button-header lang" [class.disabled]="currentLanguage === languagesEnum.FR_CA"
        text="{{ 'language.fr' | translate }}" (click)="onLanguageChange(languagesEnum.FR_CA)">
      </dx-button>
      }
      @if(languageStatus.es) {
      <dx-button class="button-header lang" [class.disabled]="currentLanguage === languagesEnum.ES_ES"
        text="{{ 'language.es' | translate }}" (click)="onLanguageChange(languagesEnum.ES_ES)">
      </dx-button>
      }
      @if(languageStatus.de) {
      <dx-button class="button-header lang" [class.disabled]="currentLanguage === languagesEnum.DE_DE"
        text="{{ 'language.de' | translate }}" (click)="onLanguageChange(languagesEnum.DE_DE)">
      </dx-button>
      }

      <dx-button class="button-header ml30" icon="icon-logout" (click)="onLogout()"
        hint="{{'header.logout' | translate}}">
        <i class="icon-logout"></i>
      </dx-button>
    </div>
    }
  </div>
</div>

<shared-popup [(visible)]="showRefreshPopup" [panelClass]="'popup small'" [animation]="'scale'" [outsideClick]="false">
  <div class="app-modal app-modal-simple">
    <div class="app-modal-header">
      <h1>&nbsp;</h1>
      <dx-button class="button-close" icon="close" (click)="showRefreshPopup = false"></dx-button>
    </div>
    <div class="app-modal-content br4">
      <div class="app-form-panel app-form-panel-simple">
        <div class="app-form-panel-content acenter">
          <div>
            <img src="../../assets/images/new_version_available.svg" alt="{{ 'actions.refresh' | translate }}"
              style="width: 330px" />
          </div>
          <h1 class="mt40 fs28">
            {{ 'general.new_version_available' | translate }}
          </h1>
          <div>
            {{ 'general.new_version_refresh_text' | translate }}
          </div>
          <dx-button class="mt25" (click)="updateToLatest()" text="{{ 'actions.refresh' | translate }}" type="default">
          </dx-button>
        </div>
      </div>
    </div>
  </div>
</shared-popup>
