import { Routes } from '@angular/router';

import { AuthGuard } from './auth/auth.guard';
import { configurationRoutes } from './configuration/configuration-routing';
import { marketingRoutes } from './marketing/marketing-routing';
import { centralBookingRoutes } from './central-booking/central-booking.routing';

export const appRoutes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'auth',
    loadComponent: () =>
      import('./auth/auth.component').then((m) => m.AuthComponent),
  },
  {
    path: 'home',
    loadComponent: () =>
      import('./home/home.component').then((m) => m.HomeComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'marketing',
    loadComponent: () =>
      import('./marketing/marketing.component').then(
        (m) => m.MarketingComponent
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'configuration',
    loadComponent: () =>
      import('./configuration/configuration.component').then(
        (m) => m.ConfigurationComponent
      ),
    canActivate: [AuthGuard],
    children: configurationRoutes,
  },
  {
    path: 'central-booking',
    loadComponent: () =>
      import('./central-booking/central-booking.component').then(
        (m) => m.CentralBookingComponent
      ),
    canActivate: [AuthGuard],
    children: centralBookingRoutes,
  },
  ...marketingRoutes,
  { path: '**', redirectTo: '/home', pathMatch: 'full' },// for any other route, redirect to home
];
